import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Card from '../components/Card'
import breadboxStyles from './breadbox.module.scss'
import { OutboundLink } from "gatsby-plugin-google-analytics"


const Apps = () => {
    return (
        <Layout className={breadboxStyles.Layout}>
            <h1 className={breadboxStyles.title}>Breadbox</h1>
            <h2>Did you know that bread boxes used to be an absolute necessity to keep bread fresh? Luckily, today, we can keep our recipes fresh and organized using the BreadBox app. </h2>
            <ul>
                <li>
                    You found the new Sourdough formula on Instagram? Add it.
                </li>
                <li>
                    Someone show you how to use that bread machine that's just been sitting in your pantry collecting dust? Add it.
                </li>
                <li>
                    You finally watched that YouTube video that shows just how easy it is to make your pizza dough from scratch? Add it.
                </li>
            </ul>
            <h3>With the built-in Reference Guide, you'll have quick access to temperature conversions, weight and volume conversions between Imperial and Metric so you'll never get that formula wrong again as well as some quick baking terms that you'll be guaranteed to learn something new. </h3>
            <div className="CardGroup">
                <div className="Cards">
                    <Card image={require("../images/screenshots/screen-breadbox-1.png")} />
                </div>
                <div className="Cards">
                    <Card image={require("../images/screenshots/screen-breadbox-2.png")} />
                </div>
                <div className="Cards">
                    <Card image={require("../images/screenshots/screen-breadbox-3.png")} />
                </div>
                <div className="Cards">
                    <Card image={require("../images/screenshots/screen-breadbox-4.png")} />
                </div>
            </div>
            <OutboundLink href="https://apps.apple.com/us/app/breadbox/id1515224126" target="_blank" rel="noopener noreferrer"><img src={require('../images/logo-appStore.png')} height="50"></img></OutboundLink>
        </Layout>
    )
}

export default Apps
